import { gql } from "@apollo/client";

// LOCAL STATE

export const GET_CART_OPEN = gql`
  query GET_CART_OPEN {
    # cartOpen @client
    cartOpen @client {
      cartOpen
    }
  }
`;

export const GET_ACCOUNT_MENU_OPEN = gql`
  query GET_ACCOUNT_MENU_OPEN {
    # cartOpen @client
    accountMenuOpen @client {
      accountMenuOpen
    }
  }
`;

export const GET_IS_LOGGED_IN = gql`
  query GET_IS_LOGGED_IN {
    isLoggedIn @client {
      isLoggedIn
    }
  }
`;

export const GET_CART_ITEMS = gql`
  query GET_CART_ITEMS {
    cartItems @client {
      cartItems
    }
  }
`;

export const GET_USERS_COMPANY = gql`
  query GET_USERS_COMPANY {
    company @client 
  }
`;

export const GET_USER_STORE = gql`
  query GET_USER_STORE {
    store @client 
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GET_NOTIFICATIONS {
    notifications @client {
      notifications
    }
  }
`;

export const GET_USER = gql`
  query GET_USER {
    user @client {
      id
      email
      productLists
      homePageUrl
      productsForCampaing
      userType
      customerType
      isEnrolledInTeachable
      priceListLink
      users
    }
  }
`;

export const GET_ACTIVE_ORDER = gql`
  query GET_ACTIVE_ORDER {
    activeOrder @client {
      id
      shippingCost
      totalPrice
      totalVAT
      discount
      vatRateOrder
    }
  }
`;

export const GET_CATEGORY_TARGET = gql`
  query GET_CATEGORY_TARGET {
    cachedCategories @client {
      target
      categories
      # products
      loading
    }
  }
`;
export const GET_MODAL = gql`
  query GET_MODAL {
    # cartOpen @client
    modal @client {
      isOpen
      element
      message
      error
      props
    }
  }
`;

export const GET_APOLLO_ROUTER = gql`
  query GET_APOLLO_ROUTER {
    # cartOpen @client
    router @client {
      prevPath
      currentPath
    }
  }
`;

export const GET_PRE_ORDER = gql`
  query GET_PRE_ORDER {
    # cartOpen @client
    preOrder @client {
      selectedWindow
      preOrderWindowName
    }
  }
`;

export const GET_CATEGORY_PAGE = gql`
  query GET_CATEGORY_PAGE {
    # cartOpen @client
    categoryPage @client {
      selectedCategory
      selectedProduct
    }
  }
`;

// API

export const ME_QUERY = gql`
  query ME_QUERY($token: String) {
    getCurrentUser(token: $token) {
      user {
        id
        email
        userType
        company {
          id
          customerType
          currency {
            currency_code
          }
          environment {
            external_ref
          }
          discountPercentage {
            discount
          }
          responsiblePerson {
            name
            email
            phoneNumber
          }
          paymentMethods
        }

        store {
          id
          name
          line_1
          zipCode
          city
          country
          warehouseId
          status
          termsAndConditions {
            termsAndConditionsAccepted
          }
          country
          users {
            id
            email
            userType
          }
          # termsAndConditions
          external_ref
          basePrice {
            name
          }
          priority
          company {
            customerDefinition
            currency {
              currency_code
            }
            responsiblePerson {
              name
            }
          }
          warehouse {
            name
          }
        }
      }
      homePageUrl
      priceListLink
      isEnrolledInTeachable
      vatRate
      productLists {
        id
        external_ref
      }

      users {
        id
        email
      }

      productsForCampaing {
        name
        id
        campaigns {
          label
          discount
          name
        }
      }

      categories {
        id
        name
        target
        slug
        productSlug
        translations {
          field
          translation
          language
        }
      }

      productsForCampaing {
        name
        id
        campaigns {
          label
          discount
          name
        }
      }

      order {
        id
        edit
        paid
        shippingCost
        totalPrice
        items {
          quantity
          id
          price
          item {
            id
            name
            sku
            inventory
            color
            size
            version
            product {
              id
              name
              applyDiscount
            }
            prices {
              baseAmount
              basePrice {
                name
              }
              currency {
                currency_code
              }
            }
          }
        }
      }

      # categories {
      #   name
      #   target
      #   products {
      #     id
      #     slug
      #   }
      # }
      notifications {
        id
        message
        type
        acknowledged {
          id
        }
      }
    }
  }
`;

export const GET_CURRENT_USER_ORDER = gql`
  query GET_CURRENT_USER_ORDER($currency: String, $basePrice: String) {
    getCurrentUserOrder {
      id
      edit
      paid
      shippingCost
      totalPrice
      totalVAT
      vatRate
      discountedBy
      items {
        quantity
        id
        price
        discountedBy
        item {
          id
          name
          sku
          inventory
          color
          size
          version
          product {
            id
            name
            applyDiscount
          }
          prices(currency: $currency, basePrice: $basePrice) {
            baseAmount
            basePrice {
              name
            }
            currency {
              currency_code
            }
            environment {
              external_ref
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query GET_ALL_NOTIFICATIONS {
    notifications {
      id
      type
      message
    }
  }
`;

export const GET_CURRENT_ORDER = gql`
  query GET_CURRENT_ORDER($preOrderWindowId: String) {
    getCurrentOrder(preOrderWindowId: $preOrderWindowId) {
      id
      shippingCost
      totalPrice
      items {
        quantity
        id
        price
        discountedBy
        item {
          id
          name
          sku
          version
          inventory
          color
          size
          product {
            id
            name
            applyDiscount
          }
          prices {
            baseAmount
            basePrice {
              name
            }
            currency {
              currency_code
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_SKUS = gql`
  query SEARCH_SKUS($searchQuery: String) {
    searchSkus(searchQuery: $searchQuery) {
      id
      name
      sku
      product {
        id
        name
        categories {
          name
        }
      }
    }
  }
`;

export const SEARCH_PRODUCTS = gql`
  query SEARCH_PRODUCTS($searchQuery: String) {
    searchProducts(searchQuery: $searchQuery) {
      id
      name
      slug
      categories {
        name
      }
      skus {
        id
        name
        sku
      }
    }
  }
`;

export const ALL_CATEGORIES = gql`
  query ALL_CATEGORIES {
    categories {
      id
      name
      rank
      target
      translations {
        field
        translation
      }
      products {
        id
        label
        #   skus {
        #     id
        #   }
      }
    }
  }
`;

export const ALL_CATEGORIES_STATIC = gql`
  query ALL_CATEGORIES_STATIC($accessPortal: Boolean) {
    categoriesStatic(accessPortal: $accessPortal) {
      category {
        id
        name
        rank
        target
      }
      products {
        id
        name
        slug
      }
    }
  }
`;

export const GET_PRODUCT_LISTS = gql`
  query GET_PRODUCT_LISTS {
    productListsByUser {
      productLists {
        environment {
          name
        }
        products {
          name
          boostedProduct {
            rank
          }
          skus {
            name
            access
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_SHEET = gql`
  query GET_PRODUCT_SHEET {
    productSheet {
      byte
    }
  }
`;

export const ALL_PRODUCTS = gql`
  query ALL_PRODUCTS {
    allProducts {
      id
      name
      status
    }
  }
`;
export const SIZE_FINDER = gql`
  query SIZE_FINDER {
    sizeFinder {
      breeds {
        name
        external_ref
      }
      sizesNotAvailableForProduct {
        productId
        sizesNotAvailable
      }
      productsWithLetterSize {
        id
        name
      }
      productsWithNumberSize {
        id
        name
      }
    }
  }
`;

export const FIND_SIZE = gql`
  query FIND_SIZE($breed: String, $weight: Float) {
    findSize(breed: $breed, weight: $weight) {
      # sizes {
      size
      standardDeviation
      secondSize
      min
      max
      firstDecimal
      # sizesNotAvailable
      cannotRecommend
      productId
      productName
    }
  }
`;

export const STORE_BY_USER = gql`
  query STORE_BY_USER {
    storeByUser {
      line_1
      line_2
      zipCode
      country
      city
      shippingEmail
      shippingPhone
      attention
      company {
        name
        organizationNumber
        billingLine1
        billingLine2
        billingCity
        billingZipCode
        billingCountry
        billingEmail
        billingPhone
        hasConfirmedInformation
      }
    }
  }
`;

export const PRODUCTS_BY_CATEGORY = gql`
  query PRODUCTS_BY_CATEGORY($categoryName: String) {
    productsByCategory(
      categoryName: $categoryName
    ) {
      id
      name
      boosted
      label
      image
      lastWeekSales
      slug
      categories {
        name
      }

      skus {
        id
        inventory
        access
        sku
      }
    }
  }
`;
export const PRODUCTS_BY_CATEGORY_STATIC = gql`
  query PRODUCTS_BY_CATEGORY_STATIC(
    $categoryName: String
    $accessPortal: Boolean
  ) {
    productsByCategoryStatic(
      categoryName: $categoryName
      accessPortal: $accessPortal
    ) {
      id
      name
      label
      boostedProduct {
        boosted
        rank
        environment {
          external_ref
        }
      }
      image
      lastWeekSales
      colors
      slug
      skus {
        id
        access
        color
        }
      productLists {
        id
        external_ref
      }
      categories {
        name
      }
    }
  }
`;

export const PRODUCT_BY_ID = gql`
  query PRODUCT_BY_ID($productId: String, $preOrderWindowId: String) {
    productById(productId: $productId, preOrderWindowId: $preOrderWindowId) {
      skus {
        id
        size
        sku
        name
        color
        colorRank
        harness
        version
        inventory
        outOfStockText
        backToStockActive
        productId
        skuBackInStock {
          active
          stockId
          id
        }
        prices {
          baseAmount
          retailPrice
          sku {
            sku
          }
          basePrice {
            name
          }
          currency {
            currency_code
          }
        }
      }
    }
  }
`;

export const SKUS_FOR_PRODUCT_PORTAL = gql`
  query SKUS_FOR_PRODUCT_PORTAL(
    $productId: String
    $currency: String
    $environment: String
    $basePrice: String
    $color: String
    $secondFilter: String
  ) {
    skusForProductPortal(
      productId: $productId
      currency: $currency
      environment: $environment
      color: $color
      secondFilter: $secondFilter
    ) {
      id
      size
      sku
      name
      image
      color
      colorRank
      harness
      version
      inventory
      outOfStockText
      backToStockActive
      productId
      skuBackInStock {
        active
        stockId
        id
      }
      price(
        currency: $currency
        environment: $environment
        basePrice: $basePrice
      ) {
        baseAmount
        retailPrice
        sku {
          sku
        }
        basePrice {
          name
        }
        currency {
          currency_code
        }
      }
    }
  }
`;

export const GET_PRICES_FOR_SKUS = gql`
  query GET_PRICES_FOR_SKUS($input: PricesBySkuInput) {
    pricesForSkusAndStocks(input: $input) {
      prices {
        baseAmount
        skuId
        sku {
          sku
        }
        retailPrice
        basePrice {
          name
        }
        currency {
          currency_code
        }
      }

      stocks {
        id
        skuId
        inventory
        warehouseId
      }
    }
  }
`;

export const GET_BOOSTED_PRODUCTS_FOR_STORE = gql`
  query GET_BOOSTED_PRODUCTS_FOR_STORE(
    $storeId: String
    $currency: String
    $basePrice: String
    $environment: String
  ) {
    boostedProductsForStore(
      storeId: $storeId
      currency: $currency
      basePrice: $basePrice
      environment: $environment
    ) {
      id
      name
      image
      slug
      categories {
        name
      }
      skus {
        prices(currency: $currency, basePrice: $basePrice, environment: $environment) {
          baseAmount
        }
      }
    }
  }
`;

export const CROSS_SALE = gql`
  query CROSS_SALE($productId: String) {
    productById(productId: $productId) {
      product {
        id
        crossSale {
          suggestedProducts {
            id
            name
            image
            categories {
              name
            }
            skus {
              prices {
                baseAmount
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_BY_NAME = gql`
  query PRODUCT_BY_NAME($productName: String) {
    productByName(productName: $productName) {
      id
      name
      image
      applyDiscount
      description
      specifications
      additionalImages
      colors
      sortValues
      translations {
        field
        translation
        language
      }
      measures {
        measure
        measureSizes {
          size
          value
          unit
        }
      }

      skus {
        id
        size
        sku
        name
        access
        color
        harness
        version
        image
        inventory
        skuBackInStock {
          active
        }
        product {
          name
          id
        }
      }
    }
  }
`;

export const SKUS_BY_PRODUCT = gql`
  query SKUS_BY_PRODUCT($productId: String) {
    skusByProduct(productId: $productId) {
      id
      size
      sku
      name
      color
      harness
      version
      inventory
      skuBackInStock {
        active
      }
      product {
        name
        id
      }
      prices {
        baseAmount
        retailPrice
        basePrice {
          name
        }
        currency {
          currency_code
        }
      }
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query GET_ORDER_BY_ID($orderId: String) {
    order(orderId: $orderId) {
      id
      totalPrice
      edit
      comment
      totalVAT
      vatRate
      status
      paid
      createdAt
      shippingCost
      storeId
      storeOrderId
      external_ref
      discountedBy
      user {
        id
        email
      }
      items {
        quantity
        quantityShipped
        price
        discountedBy
        item {
          id
          name
          sku
          version
          product {
            id
            name
          }
          prices {
            baseAmount
            basePrice {
              name
            }
            currency {
              currency_code
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDERS_BY_USER = gql`
  query GET_ORDERS_BY_USER($status: String) {
    ordersByUser(status: $status) {
      id
      createdAt
      status
      totalPrice
      external_ref
      storeOrderId
      discountedBy
      items {
        quantity
        discountedBy
      }
    }
  }
`;

export const GET_STORE_BY_USER = gql`
  query GET_STORE_BY_USER {
    storeByUser {
      name
      users {
        email
        userType
      }

      company {
        responsiblePerson {
          name
          email
          phoneNumber
        }
      }
    }
  }
`;

export const GET_ALL_PREORDER_WINDOWS = gql`
  query GET_ALL_PREORDER_WINDOWS {
    allPreOrderWindows {
      id
      name
      image
      preOrderProduct {
        product {
          id
          categories {
            id
            name
            target
          }
        }
      }
    }
  }
`;

export const GET_ALL_COURSES = gql`
  query GET_ALL_COURSES($isPublished: Boolean) {
    getAllCourses(isPublished: $isPublished) {
      id
      title
      teachableId
      description
      heading
      imageUrl
      productLists {
        id
      }
      userCourses {
        userId
        courseId
      }
    }
  }
`;

export const GET_SINGLE_COURSE = gql`
  query GET_SINGLE_COURSE($teachableId: String) {
    getSingleCourse(teachableId: $teachableId) {
      id
      title
      teachableId
      description
      heading
      imageUrl
      productLists {
        id
      }
      userCourses {
        userId
        courseId
        enrolled
        progress
        user {
          email
          
        }
      }
    }
  }
`;

export const GET_USER_COURSES = gql`
  query GET_USER_COURSES {
    getUserCourses {
      id
      enrolled
      course {
        id
        teachableId
        title
        description
        imageUrl
        heading
      }
    }
  }
`;

export const GET_COLORS = gql`
  query GET_COLORS {
    getColors {
      name
      hex
    }
  }
`;

export const CHECK_IF_USER_EXISTS = gql`
  query CHECK_IF_USER_EXISTS($email: String) {
    checkIfUserExists(email: $email)
  }
`;

export const GET_SORT_VALUES_FOR_PRODUCT = gql`
  query GET_SORT_VALUES_FOR_PRODUCT($productId: String) {
    getSortValuesForSkus(productId: $productId) {
      strings
      shouldUse
    }
  }
`;

export const GET_LAST_CREATED_SETTING = gql`
  query GET_LAST_CREATED_SETTING {
    getLastCreatedSetting {
      maintenanceMode
    }
  }
`;


// export const GET_COLORS = gql`
//   query GET_COLORS {
//     getColors {
//       name
//       hex
//     }
//   }
// `;


export const GET_INVOICES = gql`
  query GET_INVOICES {
    getInvoices {
      id
      status
      documentDueDate
      external_ref
      documentDate
      currency {
        currency_code
      }
      amount
      amountInCurrency
    
    }
  }
`;

export const GET_CURRENT_ENVIRONMENT = gql`
  query GET_CURRENT_ENVIRONMENT {
    getCurrentEnvironment {
      external_ref
    }
  }
`;

export const CHECK_ORDER_INVENTORY = gql`
  mutation CHECK_ORDER_INVENTORY($orderId: String, $remove: Boolean) {
    checkOrderInventory(orderId: $orderId, remove: $remove) {
      removedSkus
      counter
      order {
        id
        totalPrice
        totalVAT
        vatRate
        discountedBy
        edit
        comment
        status
        paid
        discountedBy
        shippingCost
        items {
          quantity
          id
          price
          item {
            id
            sku
            name
            version
            product {
              id
              name
            }
            prices {
              baseAmount
              basePrice {
                name
              }
              currency {
                currency_code
              }
            }
          }
        }
      }
    }
  }
`;

// export const GET_CUSTOMER_INVOICES = gql`
//   query GET_CUSTOMER_INVOICES {
//     getInvoices {
//       status
//       documentDueDate
//       external_ref
//       documentDate
//       currency {
//         currency_code
//       }
//       amount
//       amountInCurrency
//       order {
//         totalPrice
//       }
//     }
//   }
// `;


export const GET_ALL_US_STATES = gql`
  query GET_ALL_US_STATES {
    getStates {
      id
      name
      code
      country {
        code
      }
    }
  }
`;


export const GET_INVOICE_BY_ID = gql`
  query GET_INVOICE_BY_ID($invoiceId: String) {
    invoice(invoiceId: $invoiceId) {
      id
      status
      documentDueDate
      external_ref
      documentDate
      currency {
        currency_code
      }
      amount
      amountInCurrency
    
    }
  }
`;


export const CREATE_PAYMENT = gql`
  mutation CreatePayment($environment: String!, $invoiceNumber: String!) {
    createPayment(environment: $environment, invoiceNumber: $invoiceNumber) {
      message
    }
  }
`;